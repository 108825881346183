/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { AxiosError } from 'axios';
import {
  Button,
  Form,
} from 'react-bootstrap';
import './ViewsSwitchBoard.css';
import { AsyncState } from '../../utils/webRequests.type';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import ViewsSaveFeedback from './ViewsSaveFeedback';
import {
  ViewOperationTypes,
  AlertTypes,
} from './ViewFeedback.types';
import {
  deleteQuickSightDashboardResource,
  patchOrganization,
  postQuickSightDashboardResource,
} from '../../controllers/user-service';
import {
  ModuleType,
  ViewDataType,
} from '../../controllers/user-service/types';
import {
  masterViewFlavorMap,
} from '../../utils/masterViews';
import {
  selectResourceStatus,
  selectOrganizationData,
  selectOrganizationRequestedAction,
  setOrganizationRequestedAction,
  selectSubscribedCollectionTypeMap,
  selectMasterViewList,
  selectSubscribedViewList,
  loadOrganizationData,
} from '../../reducers/orgAdmin/orgAdminSlice';
import SnackBar from '../snackBar/snackBar';
import {
  LeftNavWithExpiration,
  LeftNavWithExpirationTree,
  NestingLeftNavElement,
  ModulesPayload,
  ViewsSaveDataListType,
} from './types';
import noSpaces from '../../utils/noSpaces';
import { AppDispatch } from '../../app/store';
import { FastApiError } from '../../controllers/types';
import { getTodayAsString } from '../../utils/time';
import { extractErrorMessage } from '../../utils/fastAPIErrorHandling';
import { hasCommonItems } from '../../utils/partialMatch';

// function used to summaraize the list of selected views in OrgAdmin
// so that we can compare the current list with the list in the database
// (determine if there are changes to be saved)
const extractSelectedLeftNavViews = (
  orgViewsDict: { [key: string]: LeftNavWithExpiration },
  todayString: string,
): string[] => Object.values(orgViewsDict)
  .filter(
    ({ isEnabled, expirationDate }) => (
      isEnabled && (expirationDate ?? todayString <= '')),
  )
  .map(({ friendlyName }) => friendlyName);

function ViewsSwitchBoard() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const aYearFromNow = new Date();
  aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
  const [expirationDate, setExpirationDate] = useState<string>(aYearFromNow.toISOString().substring(0, 10));

  const [organizationViewsDict, setOrganizationViewsDict] = useState<{ [key: string]: LeftNavWithExpiration }>({});
  const [organizationViewsNestedList, setOrganizationViewsNestedList] = useState<NestingLeftNavElement[]>([]);

  const [digestiveState, setDigestiveState] = useState<AsyncState>('uninitialized');
  const [qsResourceUpdateState, setQsResourceUpdateState] = useState<AsyncState>('uninitialized');
  const [viewFilter, setViewFilter] = useState<string>('');

  const viewsSaveRef = useRef<ViewsSaveDataListType>({
    currentIndex: 0, // controls the version of this ref (for visual updates)
    currentDataIndex: 0, // controls the index of the working element
    data: [], // list of views to be acted upon
    status: 'secondary', // general status (class) of operation
    title: 'QuickSight Dashboard Resource Management Status', // title of feedback modal
  });

  const [dangerBarMessage, setDangerBarMessage] = useState<string>('');

  const resourceStatus = useSelector(selectResourceStatus);
  const organizationData = useSelector(selectOrganizationData);
  const masterViewList = useSelector(selectMasterViewList);
  const subscribedCollectionTypeMap = useSelector(selectSubscribedCollectionTypeMap);
  const storedSubscribedViewList = useSelector(selectSubscribedViewList);
  const organizationRequestedAction = useSelector(selectOrganizationRequestedAction);

  const [refIndex, setRefIndex] = useState<number>(viewsSaveRef.current.currentIndex);

  const today = getTodayAsString();

  // load data.
  const addToOrgMenu = (
    orgMenu: NestingLeftNavElement[],
    breadcrumbElement: string,
    path: string | null,
    displayOrder: number,
    keys: string[],
  ) => {
    const returnValue = JSON.parse(JSON.stringify(orgMenu));
    if (returnValue.filter((v: NestingLeftNavElement) => v.friendlyName === breadcrumbElement).length === 0) {
      returnValue.push({
        friendlyName: breadcrumbElement,
        children: [],
        displayOrder,
        path,
        keys,
      });
    } else {
      const oIndex = returnValue.findIndex((v: NestingLeftNavElement) => v.friendlyName === breadcrumbElement);
      returnValue[oIndex].displayOrder = (
        displayOrder < returnValue[oIndex].displayOrder)
        ? displayOrder : returnValue[oIndex].displayOrder;
    }

    return returnValue;
  };

  const sortByDisplayOrder = (a: NestingLeftNavElement, b: NestingLeftNavElement) => a.displayOrder - b.displayOrder;

  const sortOrgMenuDict = (augmentedNestList: NestingLeftNavElement[]) => {
    const returnValues = augmentedNestList.sort(sortByDisplayOrder);
    for (let i = 0; i < returnValues.length; i += 1) {
      if (returnValues[i].children.length > 0) {
        returnValues[i].children = sortOrgMenuDict(returnValues[i].children);
      }
    }
    return returnValues;
  };

  const loadOrganizationViews = (orgModuleList: ModuleType[]) => {
    const orgMenuDict: LeftNavWithExpirationTree = {};
    let orgMenu: NestingLeftNavElement[] = [];

    const subscribedCollectionTypes: string[] = Object.keys(subscribedCollectionTypeMap);

    // filtering by subscribed collection types.
    masterViewList.filter(
      ({ required_collection_types }) => hasCommonItems(
        required_collection_types,
        subscribedCollectionTypes,
      ),
    ).forEach((masterView) => {
      orgMenuDict[masterView.path] = {
        friendlyName: masterView.friendly_name,
        breadcrumbList: masterView.breadcrumb.split(' -> '),
        displayOrder: masterView.display_order,
        isEnabled: false,
        requiredCollectionTypes: masterView.required_collection_types,
        expirationDate: null,
      };
    });

    orgModuleList.filter((v) => v.expiration_date >= today)
      .forEach((orgModule) => {
        orgModule.view_data.forEach(
          ({ path }) => {
            if (path in orgMenuDict) {
              orgMenuDict[path].isEnabled = true;
              orgMenuDict[path].expirationDate = orgModule.expiration_date;
            }
          },
        );
      });

    // loop through collectionTypeList (check requirements / deactivationDates)
    Object.keys(orgMenuDict).forEach(
      (path) => {
        let expDate: string | undefined;
        orgMenuDict[path].requiredCollectionTypes.forEach(
          (collectionType) => {
            if (Object.keys(subscribedCollectionTypeMap).indexOf(collectionType) >= 0
              && (subscribedCollectionTypeMap[collectionType] ?? '') > (expDate ?? '')
            ) {
              expDate = subscribedCollectionTypeMap[collectionType];
            }
          },
        );
        orgMenuDict[path].isEnabled = (
          (expDate ?? '') >= today
            && (orgMenuDict[path].expirationDate ?? '') >= today
        );
      },
    );

    // convert the tree to assist with display
    Object.keys(orgMenuDict).forEach(
      (path) => {
        const menuItem = orgMenuDict[path];

        // Level 0
        orgMenu = addToOrgMenu(
          orgMenu,
          menuItem.breadcrumbList[0],
          menuItem.breadcrumbList.length === 1 ? path : null,
          menuItem.displayOrder,
          [menuItem.breadcrumbList[0]],
        );

        // level 1
        const level0Index = orgMenu.findIndex((v: NestingLeftNavElement) => v.friendlyName === (menuItem.breadcrumbList[0] ?? '').trim());
        if (menuItem.breadcrumbList.length > 1
          && level0Index >= 0
        ) {
          orgMenu[level0Index].children = addToOrgMenu(
            orgMenu[level0Index].children,
            menuItem.breadcrumbList[1],
            menuItem.breadcrumbList.length === 2 ? path : null,
            menuItem.displayOrder,
            menuItem.breadcrumbList.slice(0, 2),
          );
        }

        // level 2
        const level1Index = orgMenu[level0Index].children.findIndex((v: NestingLeftNavElement) => v.friendlyName === (menuItem.breadcrumbList[1] ?? '').trim());
        if (menuItem.breadcrumbList.length > 2
          && level1Index >= 0
        ) {
          orgMenu[level0Index].children[level1Index].children = addToOrgMenu(
            orgMenu[level0Index].children[level1Index].children,
            menuItem.breadcrumbList[2],
            menuItem.breadcrumbList.length === 3 ? path : null,
            menuItem.displayOrder,
            menuItem.breadcrumbList.slice(0, 3),
          );
        }
      },
    );

    setOrganizationViewsDict(orgMenuDict);
    setOrganizationViewsNestedList(sortOrgMenuDict(orgMenu));

    setDigestiveState('completed');
  };

  const isOkToRender = () => (organizationData !== null
    && resourceStatus === 'completed'
  );

  // digest.
  useEffect(() => {
    if (resourceStatus === 'failed') {
      navigate('/error');
    }

    if (!isOkToRender()) {
      return;
    }

    setDigestiveState('uninitialized');

    const moduleList: ModuleType[] = Object.values(organizationData.modules);

    loadOrganizationViews(moduleList);
  }, [
    organizationData,
    resourceStatus,
    subscribedCollectionTypeMap,
  ]);

  const handleQsError = (
    error: AxiosError<FastApiError>,
  ) => {
    datadogRum.addError(error);

    // extracting the error message.. but it could come in multiple forms.
    const message = extractErrorMessage(error);

    viewsSaveRef.current.data[viewsSaveRef.current.currentDataIndex].status = 'failed';
    viewsSaveRef.current.data[viewsSaveRef.current.currentDataIndex].detail = message;
    viewsSaveRef.current.status = 'danger';
    viewsSaveRef.current.currentDataIndex += 1;
    viewsSaveRef.current.currentIndex += 1;
    setRefIndex(viewsSaveRef.current.currentIndex);
  };

  const handleQsResponse = () => {
    viewsSaveRef.current.data[viewsSaveRef.current.currentDataIndex].status = 'completed';
    viewsSaveRef.current.currentDataIndex += 1;
    viewsSaveRef.current.currentIndex += 1;
    setRefIndex(viewsSaveRef.current.currentIndex);
  };

  // ! HACK: please restore the post/delete 2nd parameters to `view.reportName`
  // Pull and do one operation.
  const runSingleQSResourceOperationFromQueue = async () => {
    if (viewsSaveRef.current.currentDataIndex >= viewsSaveRef.current.data.length) {
      return;
    }
    const view = viewsSaveRef.current.data[viewsSaveRef.current.currentDataIndex];
    const oid = (organizationData === null) ? '-1' : String(organizationData.id);
    switch (view.operation) {
      case 'create':
        viewsSaveRef.current.data[viewsSaveRef.current.currentDataIndex].status = 'loading';
        viewsSaveRef.current.currentIndex += 1;
        setRefIndex(viewsSaveRef.current.currentIndex);
        await postQuickSightDashboardResource(
          Number(oid),
          view.reportName,
        )
          .then(() => handleQsResponse())
          .catch((error) => handleQsError(error))
          .finally(() => setTimeout(runSingleQSResourceOperationFromQueue, 800));

        break;
      case 'destroy':
        viewsSaveRef.current.data[viewsSaveRef.current.currentDataIndex].status = 'loading';
        viewsSaveRef.current.currentIndex += 1;
        setRefIndex(viewsSaveRef.current.currentIndex);
        await deleteQuickSightDashboardResource(
          Number(oid),
          view.reportName,
        )
          .then(() => handleQsResponse())
          .catch((error) => handleQsError(error))
          .finally(() => setTimeout(runSingleQSResourceOperationFromQueue, 800));
        break;
      case 'ignore':
      default:
        viewsSaveRef.current.currentDataIndex += 1;
        setTimeout(runSingleQSResourceOperationFromQueue, 0);
        break;
    }
  };

  // calcs the difference between org state and requested state
  const calcDiffViews = (
    modulesPayload: ModulesPayload,
  ) => {
    if (organizationData === null) {
      return null;
    }

    // stuff to be removed
    let diffViews: { [key: string]: ViewOperationTypes } = (
      Object.values(organizationData.modules)
        .flatMap((module) => module.view_data)
        .reduce((acc, { quicksight_dashboard_id }) => ({
          ...acc,
          [quicksight_dashboard_id]: 'destroy',
        }), {})
    );

    // and the new stuff
    diffViews = (
      Object.values(modulesPayload)
        .flatMap((module) => module.view_data)
        .reduce((acc, { quicksight_dashboard_id }) => ({
          ...acc,
          [quicksight_dashboard_id]: (quicksight_dashboard_id in diffViews) ? 'ignore' : 'create',
        }), diffViews)
    );

    // Initialize the state object for feedback.. will also act as task manager
    const viewsSaveDataTemp: ViewsSaveDataListType = {
      currentIndex: 0,
      currentDataIndex: 0,
      data: [],
      status: 'secondary',
      title: viewsSaveRef.current.title,
    };

    const undesiredSuffix = `_${String(organizationData.id)}`;

    // initial population
    viewsSaveDataTemp.data = Object.keys(diffViews).map(
      (dashboardId) => ({
        operation: diffViews[dashboardId],
        status: 'uninitialized',
        reportName: dashboardId.replace(undesiredSuffix, ''),
      }),
    );

    if (Object.keys(diffViews).length === 0) {
      viewsSaveDataTemp.data = [];
    }

    return viewsSaveDataTemp;
  };

  // Makes a differential between existing Org state, and requested state.
  const manageQuickSightDashboardResources = async (
    modulePayload: ModulesPayload,
  ) => {
    if (organizationData === null) {
      return;
    }

    const oid = String(organizationData.id);

    // don't manage QS resource for org 60.. this one will ALWAYS be manual
    if (oid === '60') {
      return;
    }

    const viewsSaveDataTemp = calcDiffViews(modulePayload);
    if (viewsSaveDataTemp !== null) {
      // initial store for this operation.
      viewsSaveRef.current = viewsSaveDataTemp;
    } else {
      return;
    }

    // updating this state so that it forces a render (have the modal show up)
    setQsResourceUpdateState('loading');
    // do the work, in a SERIAL fashion.
    await runSingleQSResourceOperationFromQueue();
  };

  const saveViews = (callType = 'default') => {
    if (organizationData === null) {
      return;
    }
    const oid = String(organizationData.id);

    // don't manage QS resource for org 60.. this one will ALWAYS be manual
    if (oid === '60') {
      return;
    }

    const modulesPayload: {
      [module: string]: ModuleType;
    } = {};

    // if 'removeAll' - then don't store anything to modules.
    if (callType !== 'removeAll') {
      Object.keys(organizationViewsDict)
        // don't write to modules if expired and not included
        .filter(
          (path) => (organizationViewsDict[path].expirationDate !== null
            && organizationViewsDict[path].isEnabled
          ),
        )
        .forEach(
          (path) => {
            if (organizationViewsDict[path].expirationDate === null
              || organizationViewsDict[path].expirationDate === ''
            ) {
              organizationViewsDict[path].expirationDate = expirationDate;
            }

            const masterView = masterViewList.filter(
              (v) => v.path === path,
            );
            // if the requested view is valid (masterView.length === 1)
            // .. then proceed.  else skip
            if (masterView.length === 1) {
              const moduleName = masterView[0].module;

              const viewData: ViewDataType = {
                is_visible: masterView[0].is_visible,
                display_order: organizationViewsDict[path].displayOrder,
                friendly_name: organizationViewsDict[path].friendlyName,
                valid_permissions: masterView[0].valid_permissions,
                breadcrumb: organizationViewsDict[path].breadcrumbList.join(' -> '),
                quicksight_dashboard_id: `${masterView[0].report_name}_${oid}`,
                path,
              };

              // test to see if this module already exists.
              // if it does: append the view, else create the module
              if (moduleName in modulesPayload
              ) {
                modulesPayload[moduleName].view_data.push(viewData);
              } else {
                const module: ModuleType = {
                  settings: [],
                  view_data: [],
                  expiration_date: organizationViewsDict[path].expirationDate ?? '',
                };
                module.view_data.push(viewData);
                if (modulesPayload != null) {
                  modulesPayload[moduleName] = module;
                }
              }
            }
          },
        );

      if (callType === 'initial') {
        // compare payloadObject with the existing stuff.
        const diffViews = calcDiffViews(modulesPayload);
        if (diffViews === null) {
          return;
        }
        // test if we should display a warning
        if (diffViews.data.some((ele) => ele.operation === 'destroy')) {
          const destroyViewList = (
            diffViews
              .data
              .filter((ele) => ele.operation === 'destroy')
              .map((ele) => ele.reportName)
          );
          const message = (
            `Warning: the following views lack subscriptions and will be deleted upon save: ${destroyViewList.join(', ')}`
          );
          setDangerBarMessage(message);
        }
        return;
      }
    } // end if not removeAll

    patchOrganization(organizationData.id, { modules: modulesPayload })
      .then(
        // create / destroy QS resources
        () => manageQuickSightDashboardResources(modulesPayload),
      );
  };

  // allow a save request to be received from parent,
  useEffect(() => {
    if (organizationRequestedAction === 'removeAllViews') {
      saveViews('removeAll');
      dispatch(setOrganizationRequestedAction(null));
    }
  }, [organizationRequestedAction]);

  useEffect(() => {
    if (digestiveState === 'completed') {
      saveViews('initial');
    }
  }, [digestiveState]);

  if (digestiveState !== 'completed' || !isOkToRender()) {
    return <LoadingPage />;
  }

  const menuElementIsChecked = (
    menuElement: NestingLeftNavElement,
  ) => {
    if (menuElement.path !== null && menuElement.path in organizationViewsDict) {
      return organizationViewsDict[menuElement.path].isEnabled;
    }
    for (let i = 0; i < menuElement.children.length; i += 1) {
      if (!menuElementIsChecked(menuElement.children[i])) {
        return false;
      }
    }
    return true;
  };

  const handleMenuElementChange = (
    enabled: boolean,
    menuElement: NestingLeftNavElement,
  ) => {
    const updatedOrganizationViewsDict = JSON.parse(JSON.stringify(organizationViewsDict));
    if (menuElement.path !== null && menuElement.path in updatedOrganizationViewsDict) {
      updatedOrganizationViewsDict[menuElement.path].isEnabled = enabled;
      updatedOrganizationViewsDict[menuElement.path].expirationDate = enabled ? expirationDate : null;
    }

    menuElement.children.forEach((child) => {
      if (child.path !== null && child.path in updatedOrganizationViewsDict) {
        updatedOrganizationViewsDict[child.path].isEnabled = enabled;
        updatedOrganizationViewsDict[child.path].expirationDate = enabled ? expirationDate : null;
      }

      child.children.forEach((grandchild) => {
        if (grandchild.path !== null && grandchild.path in updatedOrganizationViewsDict) {
          updatedOrganizationViewsDict[grandchild.path].isEnabled = enabled;
          updatedOrganizationViewsDict[grandchild.path].expirationDate = enabled ? expirationDate : null;
        }
      });
    });

    setOrganizationViewsDict(updatedOrganizationViewsDict);
  };

  const handleOnChangeExpirationDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dateString = e.currentTarget.value.trim();
    setExpirationDate(dateString);
  };

  const saveDisabled = () => {
    if (organizationData === null) {
      return true;
    }
    const oid = String(organizationData.id);

    // don't manage QS resource for org 60.. this one will ALWAYS be manual
    if (oid === '60') {
      return true;
    }

    // if work in progress
    if (viewsSaveRef.current.data.length > 0) {
      return true;
    }

    const listView = extractSelectedLeftNavViews(organizationViewsDict, today);

    if (!storedSubscribedViewList || !listView) {
      return true;
    }

    const copyStoredViews = JSON.parse(JSON.stringify(storedSubscribedViewList));
    const a = JSON.stringify(copyStoredViews.sort());
    const b = JSON.stringify(listView.sort());
    return (a === b);
  };

  const saveViewsSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    saveViews();
  };

  const containsViewFilter = (
    (v: string) => v.toLowerCase().includes(viewFilter.toLowerCase())
  );

  return (
    <div
      id="view-switchboard"
      className="view-switchboard-container"
    >
      <ViewsSaveFeedback
        key={`ViewsSaveFeedback-${refIndex}`}
        header={viewsSaveRef.current.title}
        dataRef={viewsSaveRef}
        show={qsResourceUpdateState === 'loading'}
        onClose={
          () => {
            setQsResourceUpdateState(viewsSaveRef.current.status === 'success' ? 'completed' : 'failed');
            dispatch(loadOrganizationData(organizationData.id)); // refresh the org data
          }
        }
        style={{
          position: 'absolute',
          top: '2rem',
          width: '40vw',
          left: '30vw',
          zIndex: '1000',
        }}
      />
      <SnackBar
        data-testid="dangerBar"
        show={dangerBarMessage.length > 0}
        alertText={dangerBarMessage}
        alertType={'danger' as AlertTypes}
        onClose={() => setDangerBarMessage('')}
        header="Danger: Unsubscribed Views!"
        style={{
          position: 'absolute',
          width: '40vw',
          zIndex: 1001,
          top: '10rem',
        }}
      />
      <div className="row">
        <div className="col-12">
          <h2>
            Views and Dashboards
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Form.Label htmlFor="view-search-input">
            <strong>View Filter:</strong>
          </Form.Label>
          <Form.Control
            id="view-search-input"
            data-testid="view-search-input"
            type="text"
            value={viewFilter}
            placeholder="Search Views"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setViewFilter(e.currentTarget.value);
            }}
          />
        </div>
        <div className="col-6">
          <Form.Label htmlFor="view-expiration">
            <strong>New Views Expiration Date:</strong>
          </Form.Label>
          <Form.Control
            id="view-expiration"
            data-testid="view-expiration"
            type="date"
            value={expirationDate}
            placeholder="New Views Expiration Date"
            onChange={handleOnChangeExpirationDate}
          />
        </div>
      </div>
      <hr className="mt-3 mb-1" />
      {
        organizationViewsNestedList
          .filter(
            (menuGroup) => (
              menuGroup.keys.some(containsViewFilter)
              || menuGroup.children.some(
                (mG2) => (
                  mG2.keys.some(containsViewFilter)
                  || mG2.children.some(
                    (mG3) => mG3.keys.some(containsViewFilter),
                  )
                ),
              )
            ),
          )
          .map((menuGroup, groupIndex) => {
            const groupText = (
              <div>
                <h3>{menuGroup.friendlyName}</h3>
                <p className="muted">
                  {masterViewFlavorMap(menuGroup.friendlyName)}
                </p>
              </div>
            );
            const id = noSpaces(`group-${menuGroup.friendlyName}-label`);
            const containerId = noSpaces(`view-${menuGroup.friendlyName}`);
            return (
              <div id={containerId} key={containerId} className="row menuGroup-row">
                <Form.Check
                  type="switch"
                  id={id}
                  data-testid={id}
                  label={groupText}
                  onChange={(event) => handleMenuElementChange(event.currentTarget.checked, menuGroup)}
                  checked={menuElementIsChecked(menuGroup)}
                />
                <div key={`group-${menuGroup.friendlyName}-${groupIndex}`}>
                  {
                    menuGroup.children
                      .filter(
                        (menuElement) => (
                          menuElement.keys.some(containsViewFilter)
                          || menuElement.children.some(
                            (mG2) => (
                              mG2.keys.some(containsViewFilter)
                            ),
                          )
                        ),
                      )
                      .map((menuElement, menuIndex) => {
                        const elementText = (
                          <div>
                            <h4>{menuElement.friendlyName}</h4>
                            <p className="muted">
                              {masterViewFlavorMap(menuElement.friendlyName)}
                            </p>
                          </div>
                        );
                        const elementTag = noSpaces(`${menuGroup.friendlyName}-${menuElement.friendlyName}`);
                        return (
                          <div
                            key={`view-${elementTag}`}
                            className="submodule-container col-10 offset-2"
                          >
                            <Form.Check
                              type="switch"
                              id={`view-${elementTag}-label`}
                              data-testid={`view-${elementTag}-label`}
                              label={elementText}
                              onChange={(event) => handleMenuElementChange(event.currentTarget.checked, menuElement)}
                              checked={menuElementIsChecked(menuElement)}
                            />
                            <div key={`view-${menuGroup.friendlyName}-${menuElement.friendlyName}-${menuIndex}`}>
                              {
                                menuElement.children
                                  .filter(
                                    (menuSubElement) => (
                                      menuSubElement.keys.some(containsViewFilter)
                                    ),
                                  )
                                  .map((menuSubElement) => {
                                    const subElementText = (
                                      <div>
                                        <h4>{menuSubElement.friendlyName}</h4>
                                        <p className="muted">
                                          {masterViewFlavorMap(menuSubElement.friendlyName)}
                                        </p>
                                      </div>
                                    );
                                    const subElementTag = noSpaces(`${menuGroup.friendlyName}-${menuElement.friendlyName}-${menuSubElement.friendlyName}`);
                                    const subElementId = noSpaces(`view-${menuSubElement.friendlyName}`);
                                    return (
                                      <div
                                        id={subElementId}
                                        key={`view-${subElementTag}`}
                                        className="submodule-container col-9 offset-3"
                                      >
                                        <Form.Check
                                          type="switch"
                                          id={`view-${subElementTag}-label`}
                                          data-testid={`view-${subElementTag}-label`}
                                          label={subElementText}
                                          onChange={(event) => handleMenuElementChange(event.currentTarget.checked, menuSubElement)}
                                          checked={menuElementIsChecked(menuSubElement)}
                                        />
                                      </div>
                                    );
                                  })
                              }
                            </div>
                          </div>
                        );
                      })
                  }
                </div>
              </div>
            );
          })
      }
      <div className="row mt-3 mb-3">
        <div className="col-6">
          &nbsp;
        </div>
        <div className="col-6 text-right">
          <Button
            id="save-views-button"
            data-testid="save-views-button"
            variant="primary"
            onClick={saveViewsSubmit}
            disabled={saveDisabled()}
          >
            Update Views
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ViewsSwitchBoard;
